<template>
    <div class="news_block">
        <time>{{ item.created_at }}</time>
        <h2>{{ item.title }}</h2>
        <div v-if="isAdmin" class="edit-links">
            <a @click="$router.push('/news/' + item.id)">Изменить</a>&nbsp; | &nbsp; <a href="#" @click="deleteNews">Удалить</a>
        </div>
        <div v-html="item.text"></div>
        <img :src="getImage" alt="" v-if="item.image" class="news-image">
    </div>
</template>
<script>
import axios from "axios";

export default {
    props: ['item', 'isAdmin', 'token'],
    computed: {
        getImage() {
            return process.env.VUE_APP_BASE_URL.split('/api/v1/').at(0) + this.item.image
        },
    },
    methods: {
        async deleteNews() {
            if (confirm('Вы действительно хотите удалить запись?')) {
                try {
                    await axios.delete(process.env.VUE_APP_BASE_URL + 'news/' + this.item.id, {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            token: this.token
                        }
                    });
                    window.location.reload();
                } catch (e) {
                    alert('Не удалось удалить запись!');
                }
            }

        }
    }
}
</script>
<style>
.edit-links a {
    color: #5f636c;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
}
</style>
