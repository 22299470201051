<script>
export default {
    name: 'pagination-ui',
    data() {
        return {
            active: 1
        }
    },
    props: {
        totalPages: {
            type: Number,
            required: true
        },
        page: {
            type: Number,
            required: true
        },
    },
    offset: 0,
    emits: [
        "changePage",
        "firstPage",
        "lastPage"
    ],
    methods: {
        activate: function (el) {
            this.active = el;
        },
        prevPage() {
            if (this.page > 1) {
                const page = this.page - 1;
                this.$emit('changePage', page);
                this.activate(page);
            }
        },
        nextPage() {
            if (this.page < this.totalPages) {
                const page = this.page + 1;
                this.$emit('changePage', page);
                this.activate(page);
            }
        }
    },
    computed: {
        offset() {
            return (this.totalPages >= this.page) ? 0 : this.page - this.totalPages
        },
        visiblePages() {
            let startPage = Math.max(this.page - 3, 1);
            let endPage = Math.min(this.page + 5, this.totalPages);
            let pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }
    }
}
</script>

<template>
    <a
            href="#"
            class="pagin__prev"
            @click="prevPage"
    ></a>
    <a
        v-if="page > 5"
        href="#"
        class="pagin__list"
        @click="$emit('firstPage'), activate(1)"
    >
        1
    </a>
    <span v-if="page > 5" style="margin-left: 5px; margin-right: 15px;">...</span>
    <ul
            v-for="pageNumber in visiblePages"
            :key="pageNumber"
            class="pagin__list"
            @click="$emit('changePage', (pageNumber + offset)), activate(pageNumber)"
    >
        <li :class="{ active : +page === +pageNumber }"><a href="#">{{ pageNumber + offset }}</a></li>
    </ul>
    <span v-if="page < totalPages - 2" style="margin-left: 5px; margin-right: 15px;">...</span>
    <a
            v-if="page < totalPages - 1"
            href="#"
            class="pagin__list"
            @click="$emit('changePage', totalPages), activate(totalPages)"
    >
        {{ totalPages }}
    </a>
    <a
        href="#"
        class="pagin__next"
        @click="nextPage"
    >
    </a>
</template>

<style scoped>

</style>
