<script>
import axios from "axios";
import SelectSetting from "@/components/setting/SelectSetting";

export default {
    name: 'GenerateFrame',
    components: {
        SelectSetting
    },
    data() {
        return {
            voices: [],
            off_voices: [],
            url: 'https://gencit.info/lat/424',
            frameUrl: 'https://gencit.info/lat/424',
            params: [
                'block',
                'voice',
                'voice_off',
                'adult_mode',
                'skips',
                'trbut',
                'trailer',
                'posters',
                'vonly',
                'disnav',
                'disfltr',
                'ser',
                'disseason',
                'alloff',
                'voices_without_ads'
            ],
            block: [
                {id: '1', name: 'Блокировка на RU', selected: false},
                {id: '2', name: 'Блокировка на UA', selected: false},
                {id: '3', name: 'Блокировка на UA и RU', selected: false},
            ],
            adult_mode: [
                {id: '1', name: 'Первый экран и плашка 18+', selected: false},
                {id: '2', name: 'Первый экран, нет плашки 18+', selected: false},
                {id: 'skips', name: 'Вырезка ЛГБТ-сцен', selected: false, value: 1,},
            ],

            trbut: [
                {id: '0', name: 'Отключение кнопки ', selected: false},
                {id: '1', name: 'Включение кнопки на всех сериях', selected: false},
                {id: 'trailer', name: 'Получить ссылку на трейлер', selected: false},
            ],

            additional_settings: [
                {
                    id: 'posters',
                    name: 'Включить постер',
                    selected: false,
                    value: 'https://img001.prntscr.com/file/img001/wGQeB5G7R6uwF_N7hsewTg.jpeg'
                },
            ],

            buttons: [
                {id: 'vonly', name: 'Все селекты сверху, кроме выбора озвучки', selected: false},
                {id: 'disnav', name: 'Стрелок (переключения пред./след. серии)', selected: false},
                {id: 'disfltr', name: 'Все селекты сверху (выбор сезона, серии и озвучки)\n', selected: false},
                {id: 'ser', name: 'Кнопки выбора сезона и озвучек', selected: false},
                {id: 'disseason', name: 'Кнопку выбора сезона', selected: false},
                {
                    id: 'alloff',
                    name: 'Все селекты сверху и автовоспроизведение след. серии',
                    selected: false
                },
            ],
            links: [
                'Работа с параметрами',
                'Выбор серии и сезона',
                'Выбор озвучки',
                'Отключение определенных озвучек',
                'Трейлер',
                'Подключение постера в плеере',
                'Блокировка плеера для определенных стран',
                '18+',
                'Отключение кнопок у плеера',
            ]
        }
    },
    methods: {
        async fetchVoices() {
            try {
                const response = await axios.get(process.env.VUE_APP_BASE_URL + 'voices', {
                    params: {},
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        token: this.token
                    }
                });

                this.voices = response.data.data.map((item) => ({
                    ...item,
                    name: `${item.name} (${item.id})`,
                    selected: false
                }));
                this.off_voices = [
                    {id: 'voices_without_ads', name: 'Отключить все озвучки с вшитой рекламой', selected: false},
                    ...response.data.data.map((item) => ({
                        ...item,
                        name: `${item.name} (${item.id})`,
                        selected: false
                    }))];
            } catch (e) {
                console.log(e);
            }
        },
        updateFrame() {
            this.frameUrl = this.resultUrl;
        },
        copy() {
            navigator.clipboard.writeText(this.resultUrl);
        },
        onChange() {
            this.frameUrl = this.resultUrl;
        }
    },
    computed: {
        resultUrl() {
            const filters = [...this.params];
            const inputUrl = this.frameUrl.includes('?') ? this.frameUrl.split('?').at(-1) : '';
            const inputParams = new URLSearchParams(inputUrl);

            const queryObject = {};

            for (let [key, value] of inputParams.entries()) {
                if (filters.includes(key)) {
                    continue;
                }
                queryObject[key] = value;
            }

            let params = {};
            Object.keys(params).forEach((param) => {
                if (!params[param].length) {
                    delete params[param]
                }
            })

            const buttons = this.buttons.filter((b) => b.selected);

            buttons.forEach((button) => {
                params[button.id] = 'true';
            })

            const additional_settings = this.additional_settings.filter((b) => b.selected);

            additional_settings.forEach((setting) => {
                params[setting.id] = setting.value || '';
            })

            const voices = this.voices.filter((v) => v.selected).map((v) => v.id);

            if (voices.length) {
                params['voice'] = voices
            }

            const block = this.block.filter((v) => v.selected).map((v) => v.id);

            if (block.length) {
                params['block'] = block
            }

            const trbut = this.trbut.filter((v) => v.selected && v.id !== 'trailer').map((v) => v.id);
            const isSelectedTrailer = Boolean(this.trbut.filter((v) => v.id === 'trailer' && v.selected).length)
            if (isSelectedTrailer) {
                params['trailer'] = null;
            }

            if (trbut.length) {
                params['trbut'] = trbut
            }

            const adult_mode = this.adult_mode.filter((v) => v.selected && v.id !== 'skips').map((v) => v.id);
            const isSelectedSkips = Boolean(this.adult_mode.filter((v) => v.id === 'skips' && v.selected).length)
            if (isSelectedSkips) {
                params['skips'] = 1;
            }

            if (adult_mode.length) {
                params['adult_mode'] = adult_mode
            }

            const isDisabledVoicesWithoutAds = Boolean(this.off_voices.filter((v) => v.id === 'voices_without_ads' && v.selected).length)

            const voices_off = this.off_voices.filter((v) => v.id !== 'voices_without_ads' && v.selected).map((v) => v.id);

            if (voices_off.length) {
                params['voice_off'] = voices_off
            }

            if (isDisabledVoicesWithoutAds) {
                params['voices_without_ads'] = null;
            }

            params = {
                ...queryObject,
                ...params,
            };

            const queryParams = Object.keys(params)
                .map(key =>
                    params[key] === null ? key : `${key}=${encodeURIComponent(params[key])}`
                )
                .join('&');


            const stringParams = decodeURIComponent(queryParams);
            return `${this.url}${stringParams.length ? '?' + stringParams : ''}`;
        }
    },
    mounted() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.token = user.token;
        this.fetchVoices();
    }
}
</script>

<template>
    <div>
        <iframe :src="frameUrl" width="676" height="380"></iframe>

        <div class="settings_block-result-url">
            <div class="settings_block-result-url__url" id="voice">
                <input v-model="frameUrl"/>
            </div>
            <div class="settings_block-result-url__buttons">
                <div @click="updateFrame">
                    <img v-bind:src="require('@/assets/img/reload.svg')" alt="" width="20" height="20">
                </div>
                <div @click="copy">
                    <img v-bind:src="require('@/assets/img/copy.svg')" alt="" width="20" height="20">
                </div>
            </div>
        </div>

        <div class="setting-settings">
            <div>
                <select-setting :on-change="onChange"
                                :items="buttons" :multiple="true" label="Отключение кнопок у плеера"></select-setting>

            </div>
        </div>
        <div class="setting-settings">
            <div>
                <select-setting :on-change="onChange"
                                :items="trbut" label="Трейлер"></select-setting>
            </div>
            <div>
                <select-setting :on-change="onChange"
                                :items="additional_settings" :multiple="true" label="Постер"></select-setting>
            </div>
            <div>
                <select-setting :on-change="onChange"
                                :items="voices" label="Выбор озвучек" :can-search="true"></select-setting>
            </div>
            <div>
                <select-setting :on-change="onChange"
                                :items="off_voices" :multiple="true" :can-search="true"
                                label="Отключение озвучек"

                ></select-setting>
            </div>

            <div>
                <select-setting :on-change="onChange"
                                :items="block" label="Блокировка по гео"></select-setting>
            </div>
            <div>
                <select-setting :on-change="onChange"
                                :items="adult_mode" label="18+"></select-setting>
            </div>

        </div>
    </div>

</template>

<style scoped>
.info-block {
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.info-block h2 {
    font-size: 1.5em;
    color: #333;
}

.info-block p {
    font-size: 1em;
    color: #555;
}

.info-block a {
    background-color: #cedee1;
    color: #134e5b;
    text-decoration: none;
}

.info-block a:hover {
    text-decoration: underline;
}

.info-block code {
    background-color: #cedee1;
    color: #134e5b;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: Consolas, "Courier New", monospace;
}

.info-block h3 {
    margin-top: 20px;
    font-size: 1.2em;
    color: #444;
}

.info-block strong {
    display: block;
}

.parameter {
    display: block;
}

.setting-select {
    padding: 11px;
}

.info-block-links a {
    color: #43afe1;
}

</style>
