import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    meta: {layout: 'login'},
    component: () => import('../views/MainView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'login'},
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {layout: 'login'},
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/stats',
    name: 'stats',
    meta: {layout: 'main'},
    component: () => import('../views/StatisticView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {layout: 'main'},
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {layout: 'main'},
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/video',
    name: 'video',
    meta: {layout: 'main', updateVideo: 1},
    component: () => import('../views/VideoView.vue')
  },
  {
    path: '/update-video',
    name: 'update-video',
    meta: {layout: 'main', updateVideo: 0},
    component: () => import('../views/UpdateVideoView.vue')
  },
  {
    path: '/domains/:id',
    name: 'domains',
    meta: {layout: 'main'},
    component: () => import('../views/DomainView.vue')
  },
  {
    path: '/sites',
    name: 'sites',
    meta: {layout: 'main'},
    component: () => import('../views/SitesView.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {layout: 'main'},
    component: () => import('../views/OrderView.vue')
  },
  {
    path: '/create-order',
    name: 'create-order',
    meta: {layout: 'main'},
    component: () => import('../views/CreateOrderView.vue')
  },
  {
    path: '/modules-dle',
    name: 'modules-dle',
    meta: {layout: 'main'},
    component: () => import('../views/ModulesDLEView.vue')
  },
]

const user = JSON.parse(localStorage.getItem('user'));
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

if (user && user.admin) {
    router.addRoute({
        path: '/users',
        name: 'users',
        meta: {layout: 'main'},
        component: () => import('../views/UserView.vue')
    });
    router.addRoute({
        path: '/create-news',
        name: 'create-news',
        meta: {layout: 'main'},
        component: () => import('../views/CreateNewsView.vue')
    });
    router.addRoute({
        path: '/news/:id',
        name: 'update-news',
        meta: {layout: 'main'},
        component: () => import('../views/UpdateNewsView.vue')
    },);
  router.addRoute({
    path: '/create-dle',
    name: 'create-dle',
    meta: {layout: 'main'},
    component: () => import('../views/CreateDleView.vue')
  });
  router.addRoute({
    path: '/dle/:id',
    name: 'update-dle',
    meta: {layout: 'main'},
    component: () => import('../views/UpdateDleView.vue')
  },);

  router.addRoute({
    path: '/graphs',
    name: 'graphs',
    meta: {layout: 'main'},
    component: () => import('../views/GraphView.vue')
  });
  router.addRoute({
    path: '/edit-settings',
    name: 'edit-settings',
    meta: {layout: 'main'},
    component: () => import('../views/EditSettingView.vue')
  });
}

export default router
