<script>
const user = JSON.parse(localStorage.getItem('user'));
export default {
    name: 'navbar-ui',
    data: () => ({
        adminLinks: [
            {title: 'Статистика', url: '/stats', image: 'statistics.svg', active_image: 'statistics_active.svg'},
            {title: 'Графики', url: '/graphs', image: 'statistics.svg', active_image: 'statistics_active.svg'},
            {title: 'Пользователи', url: '/users', image: 'news.svg', active_image: 'news_active.svg'},
            {title: 'Контент', url: '/video', image: 'content.svg', active_image: 'content_active.svg'},
            {title: 'Обновление', url: '/update-video', image: 'new.svg', active_image: 'new_active.svg'},
            {title: 'Настройки', url: '/settings', image: 'options.svg', active_image: 'options_active.svg'},
            // {title: 'Заказы', url: '/orders', image: 'orders.svg', active_image: 'orders_active.svg'},
            {title: 'Скрипты', url: '/modules-dle', image: 'module_dle.svg', active_image: 'module_dle_active.svg'},
            {title: 'Новости', url: '/news', image: 'news.svg', active_image: 'news_active.svg'},
        ],
        userLinks: [
            {title: 'Статистика', url: '/stats', image: 'statistics.svg', active_image: 'statistics_active.svg'},
            {title: 'Сайты', url: '/sites/', image: 'sites.svg', active_image: 'sites_active.svg'},
            {title: 'Контент', url: '/video', image: 'content.svg', active_image: 'content_active.svg'},
            {title: 'Обновление', url: '/update-video', image: 'new.svg', active_image: 'new_active.svg'},
            // {title: 'Заказы', url: '/orders', image: 'orders.svg', active_image: 'orders_active.svg'},
            {title: 'Настройки', url: '/settings', image: 'options.svg', active_image: 'options_active.svg'},
            {title: 'Скрипты', url: '/modules-dle', image: 'module_dle.svg', active_image: 'module_dle_active.svg'},
            {title: 'Новости', url: '/news', image: 'news.svg', active_image: 'news_active.svg'}
        ],
        links: [],
        token: '',
        admin: '',
    }),
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push({name: 'login'});
        },
        checkAuth() {
            if (user) {
                this.token = user.token;
                this.admin = user.admin;

                // if (!this.token) {
                //   this.$router.push({name: 'main'});
                // }
            }
        },
    },
    beforeMount() {
        if (!user) {
            window.location.href = "/login";
        }
    },
    mounted() {
        this.checkAuth();
        this.links = (this.admin !== null) ? this.adminLinks : this.userLinks;
    },
    computed: {
        newsCount() {
            return this.$store.state.settings.newsCount;
        }
    }
}
</script>

<template>
    <nav class="menu menu__top">
        <ul class="menu__list">
            <li class="menu__exit">
                <a @click.prevent="logout" href="#">Выйти</a>
                <img v-bind:src="require('@/assets/img/exit.svg')" alt="">
            </li>
            <router-link
                    v-for="link in links"
                    :key="link.url"
                    :to="link.url"
                    custom
                    v-slot="{ href, navigate, isActive }"
            >
                <li
                        :class="[isActive && 'active']"
                >
                    <a :href="href" @click="navigate" :class="{
              'link': link.title === 'Новости' && newsCount
          }">{{ link.title }}<span class="badge" v-if="link.title === 'Новости' && newsCount">{{ newsCount }}</span></a>
                    <img v-bind:name="href"
                         v-bind:src="require(`@/assets/img/${((isActive) ? link.active_image : link.image)}`)"/>
                </li>
            </router-link>
        </ul>
    </nav>
</template>

<style scoped>
.badge {
    background-color: #34353f;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    color: #B9BCC0;
    padding: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.link {
    display: flex;
    justify-content: space-around;
}
</style>
