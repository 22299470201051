<template>
    <div class="toggle-buttons">
        <div @click="toggleOpen" class="header">
            <span>{{ label }}</span>
            <span v-if="isOpen">
                 <img v-bind:src="require('@/assets/img/up.svg')" alt="" width="20" height="20">
            </span>
            <span v-else>
                 <img
                         v-bind:src="require('@/assets/img/down.svg')" alt="" width="20" height="20">
            </span>
        </div>
        <div v-if="isOpen" class="select-setting-container" :class="{'select-disable-scroll-y': items.length < 8}">
            <input v-if="canSearch" type="text" v-model="searchTerm" class="search-field w-100" @click.stop
                   placeholder="Поиск...">

            <label :key="key" v-for="(item, key) in filteredOptions">
                <input v-model="item.selected" type="checkbox"
                       @change="onChange"
                       :disabled="!multiple && isDisabled(item)"/>
                {{ item.name }}
            </label>
        </div>
    </div>
</template>

<script>

export default {
    props: ['items', 'label', 'model', 'multiple', 'canSearch', 'onChange'],
    data() {
        return {
            isOpen: false,
            searchTerm: '',
        }
    },
    computed: {
        filteredOptions() {
            return (this.items || []).filter(option => {
                return option.name.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen
        },
        isDisabled(item) {
            if(isNaN(item.id)) {
                return false;
            }

            if (!this.items.filter(i => i.selected && !isNaN(i.id)).length) {
                return false;
            }
            return this.items.filter(i => i.selected && item.id === i.id).length ? 0 : 1;
        }
    }
}


</script>

<style scoped>
.toggle-buttons {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Arial, sans-serif;
}

.header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.select-setting-container {
    padding: 10px;
    background-color: white;
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

label {
    display: block;
    margin: 5px 0;
    font-size: 12px;
}

.w-100 {
    width: 100%;
}

input[type="checkbox"] {
    margin-right: 8px;
}

.select-setting-container {
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

.select-disable-scroll-y {
    overflow: hidden;
}

.search-field[placeholder] {
    font-size: 12px;
    margin: 5px;
}
</style>
